import { BaseComponent } from "themes/components";
import HomeLayout from "themes/layouts/Home";
import InitData from "themes/layouts/InitData";
import {
  Banner,
  TitleGroup,
  CircleProduct,
  Comment,
  ContactBlock,
} from "themes/components/HomeComponents/HomePage";
import { ProductCard, PostCard } from "themes/components/HomeComponents";
import { Row, Col, Button, List, Card, Icon, Tooltip } from "antd";

import ProductService from "themes/services/api/Product";
import ProductCategoryService from "themes/services/api/ProductCategory";
import PostService from "themes/services/api/Post";
import route from "themes/route";

import to from "await-to-js";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import ContactService from "themes/services/api/Contact";
import slug from "slug";

class Index extends BaseComponent {
  static async getInitialProps(ctx) {
    let publicSetting = await InitData.getInitData(ctx);
    let ProductServiceObj = new ProductService(ctx);
    let ProductCategoryServiceObj = new ProductCategoryService(ctx);
    let PostServiceObj = new PostService(ctx);

    let [
      error,
      [newestProducts = [], homePosts = [], homeProductCategories = []] = [],
    ] = await to(
      Promise.all([
        ProductServiceObj.getNewestProducts(),
        PostServiceObj.getHomePosts(),
        ProductCategoryServiceObj.getHomeProductCategories(),
      ])
    );
    let metaData = {
      url: route("frontend.home.index").as,
      type: "article",
      title: "NUSEE - Vua trị nám tàn nhang số 1 Việt Nam",
    };

    return {
      ...(await super.getInitialProps(ctx)),
      publicSetting,
      newestProducts: newestProducts,
      homePosts: homePosts,
      homeProductCategories: homeProductCategories,
      error: error,
      metaData
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      notice: "",
    };
    this.ProductService = new ProductService();
  }
  componentDidMount() {
    BaseComponent.scrollTop();
  }

  onSubmitContact = async (values) => {
    let ContactServiceObj = new ContactService();
    let [error, result] = await to(ContactServiceObj.getClientContact(values));
    if (error) {
      return this.notify(`errors:${error.code}`, error.message, "error");
    }

    this.notify("Phản hồi của bạn đã gửi thành công");
  };

  renderBanner(data = [], publicSetting = {}) {
    let content = (
      <div>
        <h1 className="title1">{publicSetting.name || ""}</h1>
        <h3>{publicSetting.slogan || ""}</h3>
        <Button
          type="primary"
          size="large"
          className="button1"
          onClick={() => this.redirect("frontend.home.about")}
        >
          Xem chi tiết
        </Button>
      </div>
    );
    return data.map((item, index) => {
      return (
        <Banner
          className="banner-fullwidth"
          key={index}
          srcs={item.content}
          content={content}
        />
      );
    });
  }

  renderAboutUs = (publicSetting = {}) => {
    return (
      <div className="aboutUsSection marginBottomSection bg-grey">
        <Row type="flex" justify="center">
          <Col xs={22} md={10} className="aboutUsSection-img">
            <img src="/static/assets/img1.jpg"></img>
          </Col>
          <Col xs={22} md={12} className="aboutUsSection-text">
            <TitleGroup title="Về chúng tôi" />
            <div>{ReactHtmlParser(publicSetting.aboutUs || "")}</div>
          </Col>
        </Row>
      </div>
    );
  };

  renderProductSection = (
    newestProducts = [],
    homeProductCategories,
  ) => {
    return (
      <div className="marginBottomSection">
        <TitleGroup title="Sản phẩm mới nhất" />
        <Row type="flex" justify="center" gutter={[32, 32]}>
          <Col xs={22} md={6} className="productCateList">
            <Card>
              <List
                dataSource={homeProductCategories}
                renderItem={(item) => (
                  
                  <List.Item key={item.name}>
                    <Tooltip title="Xem danh sách">
                    <List.Item.Meta onClick={() => this.redirect("frontend.home.product_category", {id:item.id, slug: slug(item.name)})} title={item.name} />
                    </Tooltip><a><Icon type="caret-right" /></a>
                  </List.Item>
                  
                )}
              />
            </Card>
          </Col>
          <Col xs={22} md={9}>
            <ProductCard
              product={newestProducts[0]}
              coverProps={{ className: "ratio5-3" }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderCircleProductsSection = (newestProducts = []) => {
    return (
      <div className="bg-grey marginBottomSection">
        <br />
        <br />
        <Row type="flex" justify="space-around" gutter={[0, 16]}>
          {newestProducts.map((item, index) => {
            if (index == 0) return;
            return (
              <Col xs={22} md={5} key={index}>
                {index >= 1 && <CircleProduct product={item} />}
              </Col>
            );
          })}
        </Row>
        <br />
        <br />
      </div>
    );
  };

  renderPostSection = (homePosts, publicSetting) => {
    if (!homePosts.length) return "";
    return (
      <div className="postSection marginBottomSection">
        <TitleGroup title="Tin tức mới nhất" />
        <br />
        <br />
        <Row type="flex" justify="center">
          <Col xs={20} md={16}>
            <PostCard post={homePosts[0]} />
          </Col>
        </Row>
      </div>
    );
  };

  renderCommentSection = (publicSetting) => {
    let avatars = ["/static/assets/avatar1.jpg", "/static/assets/avatar2.jpg"];
    let comments = String(publicSetting.comments).split("+") || [];
    comments = comments.map((item) => String(item).split("&"));
    if (!comments.length) return "";
    return (
      <div className="bg-grey marginBottomSection">
        <br></br>
        <br />
        <TitleGroup title="Đánh giá khách hàng" />
        <Row type="flex" justify="center">
          {comments.map((item = [], index) => {
            if (index >= 2) return;
            return (
              <Col xs={22} md={10} key={index}>
                <Comment item={item} avatar={avatars[index]} />
              </Col>
            );
          })}
        </Row>
        <br />
        <br />
      </div>
    );
  };
  renderContactSection = (publicSetting) => {
    return (
      <div className="marginBottomSection">
        <TitleGroup title="Liên hệ với chúng tôi" />
        <ContactBlock
          publicSetting={publicSetting}
          onSubmit={this.onSubmitContact}
        />
      </div>
    );
  };

  render() {
    const {
      publicSetting = {},
      t,
      newestProducts = [],
      homePosts = [],
      homeProductCategories = [],
      metaData={}
    } = this.props;
    const { banner = {} } = publicSetting;
    const { topBanner = [] } = banner;
    return (
      <HomeLayout data={publicSetting} metaData={metaData}>
        {this.renderBanner(topBanner, publicSetting)}
        {this.renderAboutUs(publicSetting)}
        {this.renderProductSection(
          newestProducts,
          homeProductCategories,
          publicSetting
        )}
        {this.renderCircleProductsSection(newestProducts)}
        {this.renderPostSection(homePosts, publicSetting)}
        {this.renderCommentSection(publicSetting)}
        {this.renderContactSection(publicSetting)}
      </HomeLayout>
    );
  }
}

export default BaseComponent.export({})(Index);
