import BaseApi from './Base'

class Post extends BaseApi {
  constructor(context){
    super(context)
    this.name = 'posts'; //kế thừa CRUD từ thằng cha
}
async getNewestPosts(data){
  const url = this.route(`api.${this.name}.getNewestPosts`,data).href
  return await this.Request.get(url, data)
}
async getByCategoryId(data){
  const url = this.route(`api.${this.name}.getByCategoryId`,data).href
  return await this.Request.get(url, data)
}
async getRelatedById(data) {
  const url = this.route(`api.${this.name}.getRelatedById`,data).href
  return await this.Request.get(url, data)
}
async getTopNews(data){
  const url = this.route(`api.${this.name}.getTopNews`,data).href
  return await this.Request.get(url, data)
}
async getHomePosts(data){
  const url = this.route(`api.${this.name}.getHomePosts`,data).href
  return await this.Request.get(url, data)
}
async getDetail(data){
  const url = this.route(`api.${this.name}.getDetail`,data).href
  return await this.Request.get(url, data)
}


}

export default Post