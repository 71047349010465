import { BaseComponent } from "themes/components";
import React from "react";
import { Button, Card, Typography, Icon } from "antd";
import { formatCurrency } from "themes/utils/index";
import ReactHtmlParser from "react-html-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
const { Paragraph } = Typography;
const { Meta } = Card;

class PostCard extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      notice: "",
    };
  }
  onClick = (post = {}) => {
    this.redirect("frontend.home.post", {
      id: post.id,
      slug: post.slug,
    });
  };

  render() {
    const { post = {}, coverProps = {} } = this.props;
    return (
      <Card
        hoverable
        bordered={false}
        onClick={() => this.onClick(post)}
        cover={
          <div className={coverProps.className || "ratio4-3"}>
            <LazyLoadImage
              alt={post.title}
              src={`/thumb?src=${post.thumbnail}&w=400`}
              srcSet={`/thumb?src=${post.thumbnail}&w=700 1x,/thumb?src=${post.thumbnail}&w=300 4x`} 
              effect="blur"
              delayTime={0}
            />
          </div>
        }
        className="card-custom post-card"
      >
        <Meta
          title={post.title}
          description={
            <div>
              <p><Icon type="clock-circle" /><a>{" "}{moment(post.publishedAt).format("DD/MM/YYYY")}</a></p>
              <Paragraph>{post.description}</Paragraph>
              <Button type="primary" onClick={() => this.onClick(post)}>
                Đọc bài
              </Button>
            </div>
          }
        />
      </Card>
    );
  }
}

export default PostCard;
