import { BaseComponent } from "themes/components";
import React from "react";
import { Button, Card, Typography } from "antd";
import { formatCurrency } from "themes/utils/index";
import ReactHtmlParser from "react-html-parser";
import ellipsize from "ellipsize";
import { LazyLoadImage } from "react-lazy-load-image-component";
const { Paragraph } = Typography;
const { Meta } = Card;

class ProductCard extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      notice: "",
    };
  }
  onClick = (product = {}) => {
    this.redirect("frontend.home.product", {
      id: product.id,
      slug: product.slug,
    });
  };

  render() {
    const { product = {}, coverProps = {} } = this.props;
    return (
      <Card
        hoverable
        bordered={false}
        onClick={() => this.onClick(product)}
        cover={
          <div className={coverProps.className || "ratio4-3"}>
            <LazyLoadImage
              alt={product.name}
              src={`/thumb?src=${product.thumbnail}&w=400`}
              srcSet={`/thumb?src=${product.thumbnail}&w=700 1x,/thumb?src=${product.thumbnail}&w=300 4x`} 
              effect="blur"
              delayTime={0}
            />
          </div>
        }
        className="card-custom"
      >
        <Meta
          title={product.name}
          description={
            <div>
              <h3>
                <a>{formatCurrency(product.price)}</a>
              </h3>
              <Paragraph>{ReactHtmlParser(product.description)}</Paragraph>
              <Button type="primary" onClick={() => this.onClick(product)}>
                Xem thêm
              </Button>
            </div>
          }
        />
      </Card>
    );
  }
}

export default ProductCard;
