import BaseApi from './Base'

class ProductCategory extends BaseApi {
  constructor(context){
    super(context)
    this.name = 'productCategories'; //kế thừa CRUD từ thằng cha
}
async getDetail(data){
  const url = this.route(`api.${this.name}.getDetail`,data).href
  return await this.Request.get(url, data)
}
async getActiveItems(data){
  const url = this.route(`api.${this.name}.getActiveItems`,data).href
  return await this.Request.get(url, data)
}
async getHomeProductCategories(data){
  const url = this.route(`api.${this.name}.getHomeProductCategories`,data).href
  return await this.Request.get(url, data)
}
}

export default ProductCategory