import BaseApi from './Base'

class Contact extends BaseApi {
  constructor(context){
    super(context)
    this.name = 'contacts'; //kế thừa CRUD từ thằng cha
}
async getClientContact(data){
    const url = this.route(`api.${this.name}.getClientContact`,data).href
    return await this.Request.post(url, data)
  }
}

export default Contact
